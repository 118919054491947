<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
  >
    <v-row>
      <v-col>
        <v-row>
          <v-col class=" mt-5 d-flex justify-center">
            <div>
              <span class="text-h2 primary--text ">
                LOGO
              </span>
            </div>
          </v-col>
        </v-row>
        <v-spacer />
        <v-row class="mt-16 mb-5">
          <v-col class="d-flex justify-center">
            <div>
              <span class="text-h2 secondary--text">
                {{ $t('view_titles.login') }}
              </span>
            </div>
          </v-col>
        </v-row>

        <v-row class="d-flex justify-center">
          <v-col cols="6">
            <validation-observer v-slot="{ handleSubmit }">
              <v-form
                ref="form"
                lazy-validation
                @submit.prevent="handleSubmit(onSubmit)"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                >
                  <v-text-field
                    v-model="username"
                    :disabled="loading"
                    :clearable="!loading"
                    :error="hasErrors(errors)"
                    :label="$t('messages.username')"
                    :error-messages="errors[0]"
                    filled
                  />
                </validation-provider>

                <validation-provider
                  v-slot="{ errors }"
                  rules="required|min:6"
                >
                  <v-text-field
                    v-model="password"
                    :disabled="loading"
                    :error="hasErrors(errors)"
                    :label="$t('messages.password')"
                    :hint="$t('messages.password_hint')"
                    :error-messages="errors[0]"
                    color="primary"
                    type="password"
                    filled
                  />
                </validation-provider>

                <div :align="'center'">
                  <v-btn
                    color="darkbtn"
                    class="white--text"
                    large
                    type="submit"
                    :disabled="loading"
                  >
                    {{ $t('messages.sign_in') }}
                  </v-btn>
                </div>
              </v-form>
            </validation-observer>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col cols="6">
            <div v-show="message">
              <span class="error--text">{{ $t(message) }}</span>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <v-img
          light
          gradient="to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)"
          min-height="100vh"
          max-height="100vh"
        >
          <div class=" mt-16 d-flex justify-center">
            <span class="pt-16 text-h2 secondary--text">
              {{ $t('messages.hello') }}
            </span>
          </div>
        </v-img>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
  import { required, email, min } from 'vee-validate/dist/rules'
  import { get } from 'vuex-pathify'
  import _ from 'lodash'
  export default {
    name: 'LoginView',
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    data () {
      return {
        loading: false,
        username: '',
        password: '',
      }
    },
    computed: {
      ...get('user', ['message', 'status', 'user']),
    },
    watch: {
      message (value) {},
      user (value) {
        if (_.isEmpty(value)) {
          const query = this.$route.query
          if (Object.keys(query).length > 0) {
            this.$router.replace(query.redirect)
          } else {
            this.$router.replace({ name: 'Dashboard' })
          }
        }
      },
    },

    mounted () {
      // Define the rule messages
      extend('required', {
        ...required,
        message: this.$t('validator.required'),
      })

      extend('email', {
        ...email,
        message: this.$t('validator.email_invalid'),
      })

      extend('min', {
        ...min,
        message: this.$t('validator.password_invalid'),
      })
    },
    methods: {
      onSubmit () {
        const payload = {
          username: this.username,
          password: this.password,
        }
        this.$store.dispatch('user/login', payload)
      },
      hasErrors (errors) {
        return errors.length > 0
      },
    },
  }
</script>
